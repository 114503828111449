import "./App.css";
import TextCounter from "./components/counter";
import Footer from "./components/footer";
import Header from "./components/header";

function App() {
    return (
        <div className="App">
            <Header />
            <main className="App-main">
                <TextCounter />
            </main>
            <Footer />
        </div>
    );
}

export default App;
