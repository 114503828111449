import React, { useState, useEffect } from "react";

const kanjiRegex = /[\u4E00-\u9FFF\u3400-\u4DBF\uF900-\uFAFF]/g;
const punctuationRegex = /[、。！？]/g;
const romajiRegex = /[A-Za-z]/g;
const hiraganaRegex = /[\u3040-\u309F]/g;
const katakanaRegex = /[\u30A0-\u30FF\u31F0-\u31FF]/g;
const whitespaceRegex = /\s/g;
const numberRegex = /[0-9]/g;

const TextCounter = () => {
    const [text, setText] = useState("");

    useEffect(() => {
        const textarea = document.querySelector("textarea");
        if (textarea) {
            textarea.style.height = "auto";
            if (textarea.scrollHeight <= 400) {
                textarea.style.height = `${textarea.scrollHeight}px`;
            } else {
                textarea.style.height = "400px"; // 最大高さに固定
            }
        }
    }, [text]);

    const handleChange = (event) => {
        setText(event.target.value);
    };

    const countMatches = (input, regex) => {
        const matches = input.match(regex);
        return matches ? matches.length : 0;
    };

    const counts = [
        { label: "全文字数", value: text.length },
        { label: "漢字の数", value: countMatches(text, kanjiRegex) },
        { label: "句読点の数", value: countMatches(text, punctuationRegex) },
        { label: "ローマ字の数", value: countMatches(text, romajiRegex) },
        { label: "ひらがなの数", value: countMatches(text, hiraganaRegex) },
        { label: "カタカナの数", value: countMatches(text, katakanaRegex) },
        { label: "空白の数", value: countMatches(text, whitespaceRegex) },
        { label: "数字の数", value: countMatches(text, numberRegex) },
    ];

    return (
        <div className="text-counter">
            <textarea
                className="text-counter__textarea"
                value={text}
                onChange={handleChange}
                placeholder="ここにテキストを入力してください"
                rows={1}
            />
            <div className="text-counter__results">
                {counts.map((count, index) => (
                    <div key={index} className="text-counter__card">
                        <h3>{count.label}</h3>
                        <p>{count.value}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TextCounter;