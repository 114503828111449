import React from "react";
import logo from "../logo.svg";

const Header = () => {
    return (
        <header className="App-header">
            <div className="header-content">
                <div className="logo-container">
                    <img src={logo} className="App-logo" alt="logo" />
                </div>
                <div className="title-container">
                    <h1>TextCounter</h1>
                    <p>文字数を数えるだけのアプリケーション</p>
                </div>
            </div>
        </header>
    );
};

export default Header;
